import React from "react";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../components/faq/faq-accordion";
import ContactInfo from "../../components/contact-info/contact-info";

// Helpers
import getSortedFaqDataByCategory from "../../helpers/getSortedFaqDataByCategory";

const SpanishFAQ = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Spanish Customer Service"]);

  const title = "Preguntas frecuentes";
  const description =
    "¿Tiene alguna pregunta sobre WaFd Bank, como cuál es el número de ruta de WaFd Bank? ¡Respondemos tus preguntas principales aquí!";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/preguntas-frecuentes"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-faq-01-250.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Preguntas frecuentes"
    }
  ];

  const faqAccordionData = {
    id: "spanish-customer-service-faq-section",
    isSpanish: true,
    pageTitle: "Preguntas frecuentes",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
      <ContactInfo isSpanish={true} />
    </SecondaryLanding>
  );
};

export default SpanishFAQ;
